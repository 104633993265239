export default interface PartnerDocumentType {
  id: number;

  name: string;

  description: string;

  createdAt?: Date;

  updatedAt?: Date;

  deletedAt?: Date;
}

export const partnerDocumentTypes: PartnerDocumentType[] = [
  {
    id: 1,
    name: "CONTRATO_SOCIAL",
    description: "Contrato Social"
  },

  {
    id: 2,
    name: "CONTRATO_GOOROO",
    description: "Contrato GooRoo"
  },

  {
    id: 3,
    name: "DOCUMENTO_PARCEIRO",
    description: "Documento Sócio"
  },

  {
    id: 4,
    name: "DOCUMENTO_PROCURADOR",
    description: "Documento Procurador"
  }
];
