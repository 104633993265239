var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Page',{attrs:{"title":"Parceiros"},scopedSlots:_vm._u([{key:"filtersActions1",fn:function(){return [(_vm.hasPermissions(['CRIAR_PARCEIRO']))?_c('v-btn',{attrs:{"color":"primary accent-4","text":""},on:{"click":function($event){_vm.addPartner = true}}},[_vm._v(" Novo parceiro ")]):_vm._e(),(_vm.addPartner)?_c('PartnerManageModal',{on:{"close":_vm.closeModal,"input":_vm.fetchPartners}}):_vm._e()]},proxy:true},{key:"card1",fn:function(){return [_c('div',{staticClass:"mb-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Nome Parceiro","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filters.name),callback:function ($$v) {_vm.$set(_vm.filters, "name", $$v)},expression:"filters.name"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['###.###.###-##', '##.###.###/####-##']),expression:"['###.###.###-##', '##.###.###/####-##']"}],attrs:{"append-icon":"mdi-magnify","label":"Inscrição - CPF ou CNPJ","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filters.document),callback:function ($$v) {_vm.$set(_vm.filters, "document", $$v)},expression:"filters.document"}})],1),(_vm.partnerStatusList)?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-autocomplete',{attrs:{"hide-details":"auto","clearable":"","items":_vm.partnerStatusList,"item-text":"description","item-value":"id","outlined":"","label":"Status"},model:{value:(_vm.filters.status),callback:function ($$v) {_vm.$set(_vm.filters, "status", $$v)},expression:"filters.status"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('SelectPartner',{attrs:{"label":"SubParceiros Vinculados","on-item-selected":_vm.updateSubPartnerFilter,"item-text":(item) => `${item.name} - ${_vm.formatDocument(item.document)}`,"autocomplete-props":{
              clearable: true,
              outlined: true,
              hideDetails: 'auto'
            }}})],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"E-mail Parceiro","outlined":"","hide-details":"","clearable":""},model:{value:(_vm.filters.email),callback:function ($$v) {_vm.$set(_vm.filters, "email", $$v)},expression:"filters.email"}})],1),(_vm.isAdminGooroo && _vm.responsibleCommercials)?_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-autocomplete',{attrs:{"hide-details":"auto","clearable":"","items":_vm.responsibleCommercials,"item-text":"name","item-value":"id","outlined":"","label":"Comercial responsável"},on:{"change":_vm.updateResponsibleCommercialFilter},model:{value:(_vm.filters.responsibleCommercialId),callback:function ($$v) {_vm.$set(_vm.filters, "responsibleCommercialId", $$v)},expression:"filters.responsibleCommercialId"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-btn',{attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.applyFilters()}}},[_vm._v(" Filtrar ")])],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.partners.data,"server-items-length":_vm.partners.total,"options":_vm.options,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100]
      },"loading-text":"Carregando...","locale":"pt"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:`item.actions`,fn:function({ item }){return [(_vm.editPartner && _vm.editPartner.id === item.id)?_c('PartnerManageModal',{attrs:{"partner":_vm.editPartner,"responsibleCommercials":_vm.responsibleCommercials},on:{"input":_vm.fetchPartners,"close":_vm.closeModal,"approve":function($event){return _vm.approvePartner($event)},"refuse":_vm.refusePartner}}):_vm._e(),(_vm.hasPermissions(['ATUALIZAR_PARCEIRO']))?_c('v-btn',{staticClass:"my-1",attrs:{"small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:`item.document`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDocument(item.document))+" ")]}},{key:`item.comission.comissionPercentage`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.comission?.comissionPercentage || 0)+"% ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{attrs:{"color":_vm.statusColors(item.statusId)}},[_vm._v(_vm._s(_vm.formatStatus(item.statusId)))])]}}],null,true)}),(
        _vm.partners &&
        _vm.partners.data &&
        _vm.partners.data.length &&
        _vm.partnerStatusList &&
        _vm.partnerStatusList.length &&
        _vm.partnerContactTypes &&
        _vm.partnerContactTypes.length
      )?_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","large":"","color":"primary"},on:{"click":_vm.exportPartnersXLSX}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Baixar planilha excel ")],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }