export default interface PartnerLoanCommissionBatchStatus {
  id: number;
  name: string;
  description: string;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date | null;
}

const pendingPaymentStatus: PartnerLoanCommissionBatchStatus = {
  id: 1,
  name: "PENDENTE_PAGAMENTO",
  description: "Pendente de pagamento"
};

const paidStatus: PartnerLoanCommissionBatchStatus = {
  id: 2,
  name: "PAGAMENTO_EFETUADO",
  description: "Pagamento Efetuado"
};

const canceledStatus: PartnerLoanCommissionBatchStatus = {
  id: 3,
  name: "PAGAMENTO_CANCELADO",
  description: "Pagamento Cancelado"
};

export const PartnerLoanCommissionBatchStatusEnum = {
  PENDING_PAYMENT: pendingPaymentStatus,
  PAID: paidStatus,
  CANCELED: canceledStatus
};

export const PartnerLoanCommissionBatchStatusEnumList = [
  pendingPaymentStatus,
  paidStatus,
  canceledStatus
];

