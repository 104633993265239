
import { Vue, Component, Prop } from "vue-property-decorator";
import Partner from "@/types/partner";

@Component
export default class PartnerDeleteModal extends Vue {
  @Prop({ default: null }) readonly index!: number;
  @Prop({ default: {} }) readonly item!: Partner;

  constructor() {
    super();
  }
}
