
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import DatePicker from "../DatePicker.vue";
import { format as formatDocuments } from "@/utils/cpfOrCnpj";
import PartnerService, {
  UpdatePartnerLoanCommissionBatchParams
} from "@/services/partner-service";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PartnerLoanCommissionStatus from "@/types/partner-loan-commission-status";
import PartnerLoanCommissionBatch from "@/types/partner-loan-commission-batch";
import PartnerLoanCommissionBatchStatus, {
  PartnerLoanCommissionBatchStatusEnum
} from "@/types/partner-loan-commission-batch-status";

@Component({
  components: {
    DatePicker,
    ValidationObserver,
    ValidationProvider
  }
})
export default class PartnerUpdateCommissionStatusModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop()
  readonly commissionLoanRegisterData!: PartnerLoanCommissionBatch;
  service: PartnerService;
  isCancelled: boolean = false;
  selectedStatus: PartnerLoanCommissionStatus | null = null;
  paymentValue: string | null;
  commissionLoanStatusEnum = PartnerLoanCommissionBatchStatusEnum;
  isLoading: boolean = true;
  updateCommissionData: UpdatePartnerLoanCommissionBatchParams;
  partnerLoanCommissionStatus: PartnerLoanCommissionBatchStatus[] = [
    {
      id: 2,
      name: "PAGAMENTO_EFETUADO",
      description: "Pagamento Efetuado"
    },
    {
      id: 3,
      name: "PAGAMENTO_CANCELADO",
      description: "Pagamento Cancelado"
    }
  ];
  formatDocuments = formatDocuments;

  constructor() {
    super();
    this.service = PartnerService.getInstance();
    const statusId = this.commissionLoanRegisterData.statusId;
    const foundStatus = this.partnerLoanCommissionStatus.find(
      (status) => status.id === statusId
    );
    if (foundStatus) {
      this.selectedStatus = foundStatus;
    }
    this.paymentValue = this.commissionLoanRegisterData.totalCommissionAmount;
    this.updateCommissionData = {
      amountPaid: Number(this.commissionLoanRegisterData.totalCommissionAmount),
      paidDate: this.commissionLoanRegisterData.paidDate
        ? String(this.commissionLoanRegisterData.paidDate)
        : null,
      statusId: foundStatus?.id,
      id: this.commissionLoanRegisterData.id
    };
  }

  @Watch("paymentValue")
  updatePaimentData(): void {
    if (this.paymentValue) {
      this.updateCommissionData.amountPaid = Number(this.paymentValue);
    }
  }

  @Watch("selectedStatus")
  handleUpdateStatus(): void {
    if (this.selectedStatus) {
      this.updateCommissionData.statusId = this.selectedStatus.id;
      if (
        this.updateCommissionData.statusId ===
        this.commissionLoanStatusEnum.CANCELED.id
      ) {
        this.updateCommissionData.amountPaid = 0;
        this.paymentValue = "0";
        this.updateCommissionData.paidDate = null;
        this.isCancelled = true;
      } else {
        this.isCancelled = false;
        this.paymentValue =
          this.commissionLoanRegisterData.totalCommissionAmount;
        this.updateCommissionData.amountPaid = Number(this.paymentValue);
      }
    }
  }

  get isConfirmButtonEnabled() {
    const {
      updateCommissionData: { statusId, amountPaid, paidDate }
    } = this;

    const isCancelled = statusId === this.commissionLoanStatusEnum.CANCELED.id;

    const isConfirmEnabled = isCancelled
      ? true
      : statusId && amountPaid && paidDate !== null;

    return isConfirmEnabled;
  }

  async updateStatus(): Promise<void> {
    if (this.updateCommissionData) {
      const [error, updated] =
        await this.service.updatePartnerLoanCommissionBatchStatus(
          this.updateCommissionData
        );
      if (error || !updated) {
        this.$notify({
          type: "error",
          text: "Algo deu errado ao editar o registro de comissões!."
        });
        this.$emit("close");
      }

      this.$notify({
        type: "success",
        text: "Comissão editada com sucesso!."
      });
      this.$emit("input");
      this.$emit("close");
    }
  }
}
