
import { Vue, Component, Watch } from "vue-property-decorator";
import dayjs from "@/plugins/day-js";
import Page from "@/components/core/dashboard/Page.vue";
import Partner from "@/types/partner";
import { DataOptions, DataTableHeader } from "vuetify";
import PartnerService, {
  PartnerContactTypeList,
  PartnerFilters,
  PartnerList
} from "@/services/partner-service";
import PartnerManageModal from "@/components/partners/PartnerManageModal.vue";
import PartnerDeleteModal from "@/components/partners/PartnerDeleteModal.vue";

import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";

import Modal from "@/components/Modal.vue";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import PartnerStatus from "@/types/partner-status";
import User from "../types/user";
import SafetyService from "../services/safety-service";
import { exportToSpreadsheet } from "../utils/exportToSpreadsheet";
import SelectPartner from "../components/partners/SelectPartner.vue";

@Component({
  components: {
    SelectPartner,
    PartnerManageModal,
    PartnerDeleteModal,
    Modal,
    Page
  }
})
export default class PartnersManage extends Vue {
  options: DataOptions;
  service: PartnerService;
  safetyService: SafetyService;
  partners: PartnerList;
  headers: Array<DataTableHeader>;
  addPartner = false;
  editPartner?: Partner | null = null;
  showPartners = false;
  loading = false;
  showDeleteModal: number | null;
  partnerContactTypes: PartnerContactTypeList | null = null;
  filters: PartnerFilters = {
    name: "",
    document: "",
    status: null,
    subpartnerId: null,
    responsibleCommercialId: null,
    page: 1,
    limit: 10
  };
  partnerStatusList: PartnerStatus[] | null = null;
  responsibleCommercials: User[] | null = null;
  statusColors = (statusId: number): string => {
    switch (statusId) {
      case 1:
        return "warning";
      case 2:
        return "success";
      case 3:
        return "error";
      default:
        return "error";
    }
  };

  constructor() {
    super();
    this.service = PartnerService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.headers = [
      { text: "Cadastro", value: "createdAt", sortable: false },
      { text: "Nome do Parceiro", value: "name", sortable: false },
      { text: "Inscrição Parceiro", value: "document", sortable: false },
      { text: "Nível Parceiro", value: "level", sortable: false },
      {
        text: "Porcentagem de comissão",
        value: "comission.comissionPercentage",
        sortable: false
      },
      { text: "Status", value: "status", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.partners = {
      data: [],
      total: 0
    };
    this.showDeleteModal = null;
    this.options = {} as DataOptions;
  }

  async created(): Promise<void> {
    this.fetchPartnerStatusList();
    this.fetchPartnerContactTypeList();
    this.loadResponsibleCommercials();
  }

  async fetchPartnerStatusList(): Promise<void> {
    if (this.hasPermissions(["LISTAR_STATUS_PARCEIRO"])) {
      const [error, partnerStatusList] = await this.service.listPartnerStatus();
      if (error || !partnerStatusList) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de status"
        });
      } else {
        this.partnerStatusList = partnerStatusList;
      }
    }
  }

  async fetchPartners(): Promise<PartnerList> {
    if (this.hasPermissions(["LISTAR_PARCEIROS"])) {
      this.loading = true;
      const [error, partners] = await this.service.listPartners(this.filters);

      if (error || !partners) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de parceiros"
        });
      } else {
        this.partners = partners;
      }
      this.loading = false;
    }
    return this.partners;
  }

  async fetchPartnerContactTypeList(): Promise<void> {
    const [error, partnerContactTypes] =
      await this.service.listPartnerContactTypes();
    if (error || !partnerContactTypes) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de status"
      });
    } else {
      this.partnerContactTypes = partnerContactTypes;
    }
  }

  async loadResponsibleCommercials(): Promise<void> {
    const [error, adminGoorooUsers] = await this.safetyService.listUsers({
      page: 1,
      limit: 100000,
      types: ["ADMIN_GOOROO"]
    });
    if (error) {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.responsibleCommercials = adminGoorooUsers.items;
    }
  }

  exportPartnersXLSX(): void {
    const sheet = [
      [
        "Nome/Razão Parceiro",
        "Inscrição Parceiro",
        "Comissão",
        "Tipo de Contato",
        "Telefone Fixo",
        "Ramal",
        "Cel/Whatsapp",
        "E-mail",
        "Status"
      ]
    ];

    let maxLevels = 0;

    this.partners.data.forEach((partner) => {
      const contact =
        partner.contacts && partner.contacts.length
          ? partner.contacts[0]
          : null;

      let contactTypeDescription = "";

      if (this.partnerContactTypes) {
        this.partnerContactTypes.forEach((contactType) => {
          if (contactType.id === contact?.typeId) {
            contactTypeDescription = contactType.description;
          }
        });
      }

      let statusDescription = "";
      if (this.partnerStatusList) {
        this.partnerStatusList.forEach((status) => {
          if (status.id === partner.statusId) {
            statusDescription = status.description;
          }
        });
      }

      const row = [
        partner.name,
        this.formatDocument(partner.document),
        partner.comission?.comissionPercentage || "",
        contactTypeDescription,
        (contact?.phone || "").replace("+55", ""),
        contact?.phoneExtension || "",
        (contact?.mobilePhoneWithAreaCode || "").replace("+55", ""),
        contact?.email || "",
        statusDescription || ""
      ];

      if (partner.tree && partner.tree.length) {
        if (partner.tree.length > maxLevels) {
          maxLevels = partner.tree.length;
        }

        partner.tree.forEach((level) => {
          row.push(level.name);
          row.push(level.level.toString());
        });
      }
      sheet.push(row);
    });

    if (maxLevels > 0) {
      for (let i = 0; i < maxLevels; i++) {
        sheet[0].push("Parceiro " + (i + 1));
        sheet[0].push("Nível");
      }
    }
    exportToSpreadsheet({
      data: sheet,
      fileName: "Relatório_Parceiros_" + dayjs().format("YYYYMMDDHHmmss")
    });
  }

  async remove(partnerId: number): Promise<void> {
    if (this.hasPermissions(["ATUALIZAR_PARCEIRO"])) {
      this.closeModal();
      this.loading = true;
      const [error, partner] = await this.service.removePartner(partnerId);
      if (error) {
        this.$notify({
          type: "error",
          text: "Não foi possível remover o parceiro"
        });
      } else {
        this.$notify({
          type: "success",
          text: "Parceiro removido com sucesso!"
        });
        this.fetchPartners();
      }
      this.loading = false;
    }
  }

  async approvePartner(comissionPercentage: string): Promise<void> {
    if (this.editPartner) {
      const [error, updatedPartner] = await this.service.approvePartner(
        this.editPartner.id!,
        comissionPercentage
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else {
        this.fetchPartners();
        this.closeModal();
        this.$notify({
          type: "success",
          text: "O parceiro foi aprovado!"
        });
      }
    }
  }

  async refusePartner(): Promise<void> {
    if (this.editPartner) {
      const [error, updatedPartner] = await this.service.refusePartner(
        this.editPartner.id!
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else {
        this.fetchPartners();
        this.closeModal();
        this.$notify({
          type: "success",
          text: "O parceiro foi revogado!"
        });
      }
    }
  }

  formatDocument(doc: string): string {
    if (doc) {
      if (doc.length === 11) {
        return formatCPF(doc);
      } else if (doc.length === 14) {
        return formatCNPJ(doc);
      }
    }
    return doc;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  updateSubPartnerFilter(): void {
    this.filters.subpartnerId = this.filters.subpartnerId || null;
    this.fetchPartners();
  }

  updateResponsibleCommercialFilter(): void {
    this.filters.responsibleCommercialId =
      this.filters.responsibleCommercialId || null;
    this.fetchPartners();
  }

  applyFilters(): void {
    this.filters.page = 1;
    this.filters.limit = 10;
    this.fetchPartners();
  }

  closeModal(): void {
    this.addPartner = false;
    this.showDeleteModal = null;
    this.editPartner = null;
    this.$emit("close");
  }

  @Watch("options")
  onOptionsChanged(val: DataOptions) {
    const { page, itemsPerPage } = val;
    this.filters.page = page;
    this.filters.limit = itemsPerPage === -1 ? 0 : itemsPerPage;
    this.fetchPartners();
  }

  formatStatus(statusId: number): string | undefined {
    if (this.partnerStatusList?.length) {
      const statusItem = this.partnerStatusList.find(
        (item) => item.id === statusId
      );

      if (statusItem) {
        return statusItem.description;
      }
    }
  }

  edit(partner: Partner): void {
    this.editPartner = JSON.parse(JSON.stringify(partner));
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
