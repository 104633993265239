
import { Vue, Component, Watch } from "vue-property-decorator";
import dayjs from "@/plugins/day-js";
import Page from "@/components/core/dashboard/Page.vue";
import { DataOptions, DataTableHeader } from "vuetify";
import PartnerService, {
  FindAllPartnerLoanCommissionBatchParams,
  PartnerLoanCommissionBatchList
} from "@/services/partner-service";
import PartnerCommissionModal from "@/components/partners/PartnerCommissionModal.vue";
import DatePicker from "@/components/DatePicker.vue";

import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";

import Modal from "@/components/Modal.vue";
import PartnerLoanCommissionBatch from "@/types/partner-loan-commission-batch";
import PartnerUpdateCommissionStatusModal from "@/components/partners/PartnerUpdateCommissionStatusModal.vue";
import PartnerLoanCommissionBatchStatus, {
  PartnerLoanCommissionBatchStatusEnumList
} from "@/types/partner-loan-commission-batch-status";

@Component({
  components: {
    PartnerCommissionModal,
    Modal,
    PartnerUpdateCommissionStatusModal,
    Page,
    DatePicker
  }
})
export default class MyCommissions extends Vue {
  formatCPF = formatCPF;
  formatCNPJ = formatCNPJ;
  options: DataOptions;
  service: PartnerService;
  partnerLoanCommissionBatch: PartnerLoanCommissionBatchList;
  headers: Array<DataTableHeader>;
  activePartnerLoanComissionBatch?: PartnerLoanCommissionBatch | null = null;
  loading = false;
  filters: FindAllPartnerLoanCommissionBatchParams = {
    sort: "id:DESC"
  };
  statusList: PartnerLoanCommissionBatchStatus[] =
    PartnerLoanCommissionBatchStatusEnumList;
  dateFilterTypes: Array<string> = ["Data de lançamento", "Data de pagamento"];
  dateFilterType: string | null = null;
  editStatusData: PartnerLoanCommissionBatch | null = null;

  constructor() {
    super();
    this.service = PartnerService.getInstance();
    this.headers = [
      { text: "Lote", value: "id", sortable: true },
      { text: "Parceiro", value: "partner", sortable: false },
      {
        text: "Data Lançamento",
        value: "generationDate",
        sortable: false
      },
      { text: "Valor", value: "totalCommissionAmount", sortable: false },
      {
        text: "Status",
        value: "statusId",
        sortable: false
      },
      {
        text: "Data Pagamento",
        value: "paidDate",
        sortable: false
      },
      {
        text: "Registros do lote",
        value: "batchRegisters",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.partnerLoanCommissionBatch = {
      data: [],
      total: 0
    };
    this.options = {} as DataOptions;
  }

  async created(): Promise<void> {
    await this.list();
  }

  async list(): Promise<PartnerLoanCommissionBatchList> {
    this.loading = true;
    const [error, partnerLoanCommissionBatch] =
      await this.service.partnerLoanCommissionBatch(this.filters);

    if (error || !partnerLoanCommissionBatch) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de comissões"
      });
    } else {
      this.partnerLoanCommissionBatch = partnerLoanCommissionBatch;
    }
    this.loading = false;
    return this.partnerLoanCommissionBatch;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  applyFilters(): void {
    this.filters.page = 1;
    this.filters.limit = 10;
    this.list();
  }

  closeModal(): void {
    this.activePartnerLoanComissionBatch = null;
    this.$emit("close");
  }

  @Watch("options")
  onOptionsChanged(val: DataOptions) {
    const { page, itemsPerPage } = val;
    this.filters.page = page;
    this.filters.limit = itemsPerPage === -1 ? 0 : itemsPerPage;
    this.list();
  }

  formatStatus(statusId: number): string | undefined {
    if (this.statusList?.length) {
      const statusItem = this.statusList.find((item) => item.id === statusId);

      if (statusItem) {
        return statusItem.description;
      }
    }
  }

  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    return tableOptions;
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "id";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.batchGenerationDateStart = undefined;
    this.filters.batchGenerationDateEnd = undefined;
    this.filters.paidDateStart = undefined;
    this.filters.paidDateEnd = undefined;
  }

  @Watch("editStatusData")
  editStatus(data: PartnerLoanCommissionBatch): void {
    this.editStatusData = data;
  }

  get isAdminGooroo(): boolean {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
