
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Partner from "@/types/partner";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import { UserFlag } from "@/types/user";
import PartnerService, {
  CreatePartnerParams,
  PartnerContactTypeList,
  PartnerTree,
  UpdatePartnerParams
} from "@/services/partner-service";
import SafetyService from "@/services/safety-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import municipios from "@/data/municipios";
import dayjs from "@/plugins/day-js";
import store from "@/store";
import { AxiosError } from "axios";
import PartnerContact from "@/types/partner-contact";
import { ProviderInstance } from "vee-validate/dist/types/types";
import { partnerDocumentTypes } from "@/types/partner-document-type";
import PartnerDocument from "@/types/partner-document";
import PartnerDocumentUpload from "@/components/partners/PartnerDocumentUpload.vue";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";
import User from "@/types/user";
import UsersManageModal from "@/components/users/UsersManageModal.vue";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";
import SelectPartner from "../../components/partners/SelectPartner.vue";

@Component({
  components: {
    ValidationObserver,
    Modal,
    PartnerDocumentUpload,
    UsersManageModal,
    SelectPartner,
    ValidationProvider
  },
  directives: { mask }
})
export default class PartnerModal extends Vue {
  @Prop() readonly partner?: Partner | undefined;
  @Prop() readonly responsibleCommercials?: User[] | undefined;

  UserFlag = UserFlag;
  partnerDocumentTypes = partnerDocumentTypes;
  partnerContactTypes: PartnerContactTypeList | null = null;
  partnerTree: PartnerTree | null = null;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;
  service: PartnerService;
  safetyService: SafetyService;
  loading: boolean = false;
  loadingPartnerTree: boolean = false;
  loadingPartnerList: boolean = true;
  loadingUsers: boolean = false;
  documentAlreadyExists: boolean = false;
  documentInvalid: boolean = false;
  isValidComission: boolean = false;
  states = municipios.estados;
  cities: string[] = [];
  duplicatedEmailError: string | null = null;
  parentPartnerId: number | undefined = undefined;
  emptyContact = {
    email: "",
    mobilePhoneWithAreaCode: "",
    typeId: 1,
    phone: "",
    phoneExtension: ""
  } as PartnerContact;
  editUser: User | null = null;
  users: {
    items: User[];
    total: number;
  } = {
    items: [],
    total: 0
  };

  statusList = [
    { value: 0, text: "Pendente", color: "orange" },
    { value: 1, text: "Ativo", color: "green" },
    { value: 2, text: "Inativo", color: "red" }
  ];

  form: CreatePartnerParams | UpdatePartnerParams = {
    name: "",
    document: "",
    email: "",
    userId: this.authenticatedUser?.id,
    partnerStatusId: 1,
    address: {
      name: "",
      address: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
      zipCode: ""
    },
    contacts: [],
    identifier: {
      identifier: "",
      name: ""
    },
    user: {
      name: "",
      email: "",
      phone: ""
    },
    comission: {
      comissionPercentage: "1"
    },
    responsibleCommercial: {
      responsibleCommercialId: 0
    }
  };

  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;

  constructor() {
    super();
    this.service = PartnerService.getInstance();
    this.safetyService = SafetyService.getInstance();
  }

  mounted(): void {
    this.fetchPartnerContactTypeList();
    this.fetchPartnerTree();
    if (this.partner) {
      this.form.address = this.partner.address || this.form.address;
      this.form.parentPartner =
        this.partner.parentPartner || this.form.parentPartner;
      this.form.contacts = this.partner.contacts || this.form.contacts;
      this.form.document = this.partner.document || this.form.document;
      this.form.email = this.partner.email || this.form.email;
      this.form.identifier = this.partner.identifier || this.form.identifier;
      this.form.name = this.partner.name || this.form.name;
      this.form.partnerStatusId =
        this.partner.partnerStatusId || this.form.partnerStatusId;

      this.form.user = this.partner.user || this.form.user;
      this.form.comission = this.partner.comission || this.form.comission;
      this.form.responsibleCommercial =
        this.partner.responsibleCommercial || this.form.responsibleCommercial;
      this.parentPartnerId =
        this.partner.parentPartner?.id || this.form.parentPartner?.id;
    }

    if (this.form.contacts && this.form.contacts?.length === 0) {
      this.form.contacts?.push({ ...this.emptyContact });
    }

    this.loadUsers();
  }

  async save(): Promise<void> {
    this.loading = true;
    let error, data;

    if (!this.form.responsibleCommercial?.responsibleCommercialId) {
      this.form.responsibleCommercial = null; // required to delete the responsible commercial
    }

    if (this.partner && this.partner.id) {
      [error, data] = await this.service.updatePartner(
        this.partner.id,
        this.form as UpdatePartnerParams
      );

      if (this.parentPartnerId !== this.partner?.parentPartner?.id) {
        [error, data] = await this.service.updateParentPartner(
          this.partner.id,
          this.parentPartnerId
        );
      }
    } else {
      [error, data] = await this.service.createPartner(
        this.form as CreatePartnerParams
      );
    }

    this.loading = false;

    if (error) {
      const errorMessage = getErrorMessageFromApiError(error as AxiosError);

      if (errorMessage === "Já existe um usuário com este e-mail.") {
        this.duplicatedEmailError = errorMessage;
      }

      this.$notify({
        type: "error",
        text: errorMessage
      });
    } else {
      this.$notify({ type: "success", text: "Parceiro salvo com sucesso" });
      this.$emit("input", data);
      this.close();
    }
  }

  async loadUsers(): Promise<void> {
    if (this.partner) {
      this.loadingUsers = true;

      let userIds: number[] = [];

      if (this.partner.usersPartners && this.partner.usersPartners.length > 0) {
        userIds = this.partner.usersPartners.map(
          (userPartner) => userPartner.userId
        );
      }

      userIds.push(this.partner.userId);

      if (userIds.length > 0) {
        const [error, users] = await this.safetyService.listUsers({
          page: 1,
          limit: 100000,
          userIds,
          types: ["PARTNER_MASTER", "PARTNER"]
        });
        if (error) {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(error)
          });
        } else {
          this.users = users;
        }
      }

      this.loadingUsers = false;
    }
  }

  async fetchPartnerContactTypeList(): Promise<void> {
    const [error, partnerContactTypes] =
      await this.service.listPartnerContactTypes();
    if (error || !partnerContactTypes) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de status"
      });
    } else {
      this.partnerContactTypes = partnerContactTypes;
    }
  }

  async togglePartnerStatus(partnerId: number): Promise<void> {
    if (this.partner) {
      const [error, updated] = await this.service.activeAndDeactivePartner(
        partnerId
      );
      if (error || updated?.affected === 0) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else {
        if (this.partner.active) {
          this.$notify({
            type: "success",
            text: "Parceiro desativado com sucesso!"
          });
        } else {
          this.$notify({
            type: "success",
            text: "Parceiro ativado com sucesso!"
          });
        }
        this.partner.active = !this.partner.active;
      }
    }
  }

  async deletePartner(partner: Partner): Promise<void> {
    const userResponse = confirm(
      "Você está prestes a excluir um parceiro, com isso os usuários vinculados com o mesmo serão excluídos também. Deseja continuar?"
    );

    if (userResponse && partner.id) {
      const [error, affectedColluns] = await this.service.deletePartnerAndUsers(
        partner.id
      );
      if (error || affectedColluns?.affected === 0) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
      if (affectedColluns?.affected && affectedColluns?.affected > 0) {
        this.$notify({
          type: "success",
          text: "Parceiro deletado com sucesso!"
        });
        this.$emit("input", affectedColluns.affected);
        this.close();
      }
    }
  }

  async fetchPartnerTree(): Promise<void> {
    if (this.partner && this.partner.id) {
      this.loadingPartnerTree = true;

      const [error, partnerTree] = await this.service.getPartnerTree(
        this.partner.id
      );
      this.loadingPartnerTree = false;

      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else {
        this.partnerTree = partnerTree;
      }
    }
  }

  async validateDocument(): Promise<void> {
    this.documentAlreadyExists = false;
    this.documentInvalid = false;
    if (
      this.form.document &&
      (this.form.document.length === 18 || this.form.document.length === 14) &&
      !this.partner &&
      !this.loading
    ) {
      this.loading = true;
      // Update existing partner
      const [error, alreadyExists] = await this.service.validateDocument(
        this.form.document
      );

      this.loading = false;

      if (error) {
        this.documentInvalid = true;
      } else {
        if (alreadyExists) {
          this.documentAlreadyExists = true;
        }
      }
    }
  }

  close(): void {
    this.$emit("close");
  }

  formatDate(date: string): string {
    return dayjs(date).format("DD/MM/YYYY");
  }

  loadCities(): void {
    if (this.form.address?.state) {
      this.cities = municipios.cidadePorEstado(this.form.address?.state);
    } else {
      this.cities = [];
    }
    if (
      this.form.address?.city &&
      !this.cities.includes(this.form.address?.city)
    ) {
      this.form.address.city = "";
    }
  }

  addContact(): void {
    const contacts = this.form.contacts || [];
    contacts.push({ ...this.emptyContact });
    this.form.contacts = contacts;
  }

  removeContact(index: number): void {
    if (this.form.contacts) {
      this.form.contacts.splice(index, 1);
    }
  }

  get formTitle(): string {
    return this.partner ? "Editar parceiro" : "Cadastro de parceiro";
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  get identifierUrl(): string {
    if (this.form && this.form.identifier?.identifier) {
      return `${process.env.VUE_APP_ADMIN_PORTAL_URL}/cadastrar-empresa?partner=${this.form.identifier.identifier}`;
    }
    return "";
  }

  async copy(value: string): Promise<void> {
    await navigator.clipboard.writeText(value);
    this.$notify({ type: "success", text: "Copiado com sucesso!" });
  }

  generateIdentifier(): void {
    if (this.form.name && !this.form.identifier.identifier) {
      this.form.identifier.identifier = this.form.name
        .toLowerCase()
        .split(" ")[0];
    }
  }

  validateComission(): void {
    const validationInstance = this.$refs.obs as ProviderInstance;

    this.$nextTick(async () => {
      const comissionValidation = (await validationInstance.validate(
        "Comission",
        this.form.comission.comissionPercentage
      )) as unknown as boolean;
      this.isValidComission = comissionValidation;
    });
  }

  partnerDocumentUploaded(document: PartnerDocument): void {
    this.partner?.documents?.push(document);
  }

  async removePartnerDocument(
    document: PartnerDocument | undefined
  ): Promise<void> {
    if (document && this.partner && this.partner.documents) {
      await this.service.deletePartnerDocument(document.id);
      this.partner.documents = this.partner?.documents?.filter(
        (doc) => doc.id !== document.id
      );
    }
  }

  get currentPartnerDocuments(): Record<number, PartnerDocument | undefined> {
    const currentDocs: Record<number, PartnerDocument | undefined> = {};
    if (this.partner && this.partner.documents) {
      partnerDocumentTypes.forEach((documentType) => {
        currentDocs[documentType.id] = this.partner?.documents?.find(
          (doc) => doc.documentTypeId === documentType.id
        );
      });
    }
    return currentDocs;
  }

  formatDocument(doc: string): string {
    if (doc) {
      if (doc.length === 11) {
        return formatCPF(doc);
      } else if (doc.length === 14) {
        return formatCNPJ(doc);
      }
    }
    return doc;
  }

  async handleActivateUser(): Promise<void> {
    if (this.editUser && this.editUser.id) {
      const [error, updatedUser] = await this.safetyService.activateUser(
        this.editUser.id
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.loadUsers();
        this.editUser = null;
        this.$notify({
          type: "success",
          text: "O usuário foi ativado!"
        });
      }
    }
  }

  @Watch("parentPartnerId")
  updateParentPartner(value: Partner | null): void {
    this.parentPartnerId = value?.id || null;
  }

  async handleInactivateUser(): Promise<void> {
    if (this.editUser && this.editUser.id) {
      const [error, updatedUser] = await this.safetyService.inactivateUser(
        this.editUser.id
      );
      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      } else if (updatedUser) {
        this.loadUsers();
        this.editUser = null;
        this.$notify({
          type: "success",
          text: "O usuário foi desativado!"
        });
      }
    }
  }

  get isPartner() {
    return ["PARTNER_MASTER", "PARTNER"].includes(
      this.$store.getters["auth/authenticatedUser"]?.type
    );
  }

  get isAdminGooroo() {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }

  get loggedPartnerId() {
    return this.$store.getters["auth/loggedPartnerId"];
  }

  get hasSaveAuthorization() {
    if (this.hasPermissions(["ATUALIZAR_PARCEIRO"])) {
      if (!this.isPartner || !this.partner) {
        return true;
      }

      if (this.loggedPartnerId === this.partner?.parentPartner?.id) {
        return true;
      }

      return false;
    }
    return false;
  }
}
